.page-header {
    background-color: $gray-0;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:  var(--header-height);    
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
    z-index: 2;
    @media (max-width:1050px) {
        transition: 0.2s;        
        &.invisible  {        
            top: var(--header-height-negative);
        }
    }
    .beam {
        height: 100%;
        .beam-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media (max-width: 1200px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        .graphic {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            padding: 0 50px;
            border-left: $gray-border;
            border-right: $gray-border;
            margin-right: 50px;            
            @media (max-width: 1200px) {
                border-left: none;
            }
            @media (max-width: 760px) {
                border-left: none;
                border-right: none;
                padding: 0 20px;
            }
            &:active {
                img {
                    transform: scale(0.95);
                }
            }
        }
        .slogan {
            font-family: 'Oswald', sans-serif;
            letter-spacing: 0px;
            color:$gray-5;
            font-size: 13px;
            margin-bottom: 0px;
            @media (max-width: 900px) {
                display: none;
            }
        } 
    }
    .menu {
        display: flex;
        flex-direction: row;
        position: relative;
        @media (max-width: 760px) {
            display: none;
        }
        .navigation {
            display: flex;
            flex-direction: row;
            margin-right: 35px;
            li {
                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;      
                    padding: 0 15px;
                    font-family: 'Oswald', sans-serif;
                    text-transform: uppercase;          
                    color: $gray-5;
                    text-decoration: none; 
                }
                &:hover {
                    background-color: $gray-1;
                    a {
                        color: lighten( $main, 10%);
                    }
                }
                &:active {
                    a {
                        transform: scale(0.95);
                    }
                }
                &.active {
                    position: relative;
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom:0;
                        left:0;
                        width: 100%;
                        height: 4px;
                        background-color:$main;
                    }
                }
            }
        }
        .language {
            display: flex;
            flex-direction: column;
            position: relative;
            height:  var(--header-height);
            overflow: hidden;       
            @media (max-width: 1200px) {
                border-right: none;
            }  
            &:hover {
                overflow: visible;
                li {
                    height: 50px;
                    &.active {                        
                        height:  var(--header-height);
                    }
                }
            }
            li {
                background-color: $gray-0;
                border-bottom: $gray-border;
                border-left: $gray-border;
                border-right: $gray-border;
                height:  var(--header-height);
                top: 2px;
                position: relative;
                order:2;
                &:last-child {
                    // border-bottom: $gray-border;
                }
                &:hover {
                    background-color: $gray-1;
                    a {
                        span {                      
                            color: lighten( $main, 10%);
                        }
                    }
                }
                &:active {
                    a {
                        transform: scale(0.95);
                    }
                }
                a{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 180px;     
                    cursor: pointer;     
                    text-decoration: none; 
                    span {
                        font-family: 'Oswald', sans-serif;
                        text-transform: uppercase;      
                        margin-left: 10px;        
                    }
                }
                &.active {                    
                    border-top: none;
                    order:1;                    
                    a {
                        pointer-events: none;
                    }
                }
            }
        }
    }
    .menu-mobile-btn {
        display: none;
        @media (max-width: 760px) {
            display: flex;
            align-items: center;
        }
        background-color: $gray-0;
        border: 0px;
        padding: 0 20px;
        outline: none;
        .close {
            display: none;
        }
        &.open {        
            .burger {
                display: none;
            }    
            .close {
                display: block;
            }
        }
    }
}
.menu-mobile {
    display: none;
    &.open {
        display: block;
        @media (min-width: 760px) {
            display: none;
        }        
    }
    position: fixed;
    background-color: $gray-0;
    z-index: 1;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
    padding: calc(var(--header-height) + 10px) 20px 0;
    .navigation {
        li {
            border-bottom: $gray-border;
            a {
                display: flex;
                align-items: center;
                height: 50px;
                font-family: 'Oswald', sans-serif;
                text-transform: uppercase;          
                color: $gray-5;
                text-decoration: none; 
            }
        }
    }
}