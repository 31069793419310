.page-footer {
    background-color: $gray-0;
    top:0;
    left:0;
    width: 100%;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
    margin-top: 60px;
    .beam {
        height: 100%;
        .beam-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-height: 80px;
            @media (max-width: 1200px) {
                padding-left: 0;
                padding-right: 0;
            }
            @media (max-width: 950px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                justify-content: space-between;
                >* {
                    width: 100%;
                }
            }
        }
    }
    .copy {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 50px;
        border-left: $gray-border;
        @media (max-width: 1200px) {
            border-left: none;
        } 
        @media (max-width: 950px) {
            padding: 20px 0;
            justify-content: center;
            border-bottom: $gray-border;
        }
        @media (max-width: 450px) {
            flex-direction: column;
        }
        p {
            margin-bottom: 0px;
            margin-right: 5px;
        }
        a {
            margin-right: 5px;
        }
    }
    .language {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 40px;
        border-left: $gray-border;
        border-right: $gray-border;
        @media (max-width: 1200px) {
            border-right: none;
        }
        @media (max-width: 950px) {
            padding: 20px 0;
            border-left:none;
            justify-content: center;
        }        
        @media (max-width: 450px) {
            flex-direction: column;
        }
        >* {
            margin: 10px 10px 10px 0px;
        }
    }
}